
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function SingleMerch() {
    const navigate = useNavigate();
    const [singleMerchData, setSingleMerchData] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        sanityClient
          .fetch(
            `*[slug.current == "${slug}"]{
                title,
                slug,
                mainImage{
                  asset->{
                    _id,
                    url
                  }
                },
                description,
                url,
                sizes,
                details,
                status
              }`
          )
          .then((data) => setSingleMerchData(data[0]))
          .catch(console.error);
      }, [slug]);

  if (!singleMerchData) return <div className="loading">Loading...</div>;

  return (
    <div className="post merch">
      <div className="back-blur"></div>
      <button className="back" onClick={() => navigate(-1)}>←</button> 
      <div className="inner-post">
        <div className="post-image"><img src={urlFor(singleMerchData.mainImage).url()} alt="" /></div>

        <br/>

        <div className="post-title">{singleMerchData.title}</div>

        <div className="post-body">
          <BlockContent
            blocks={singleMerchData.description}
            projectId="i4lsnv4i"
            dataset="production"
          />
        </div>

        <div className="post-span">Sizes: {singleMerchData.sizes}</div>

        <div className="post-span">
          <BlockContent
            blocks={singleMerchData.details}
            projectId="i4lsnv4i"
            dataset="production"
          />
        </div>
          
        <div className="post-span">STATUS<br /> {singleMerchData.status}</div>

        {/* <div className="checkout"><a target="_blank"href={singleMerchData.url}>Add to basket →</a></div> */}
    </div>
    </div>
  );
}