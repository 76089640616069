import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";

export default function Landing() {
    const [landing, setLanding] = useState(null);

    useEffect(() => {
        sanityClient
        .fetch(
            `*[_type == "about"]{
            landingpage
        }`)
        .then((data) => setLanding(data[0]))
        .catch(console.error)
    }, []); 

    if (!landing) return <div className="loading">Loading...</div>

    return (
        <main>
            <section className="landing-inner">
                <div className="box-main landing-box">
                        <BlockContent
                        blocks={landing.landingpage}
                        projectId="i4lsnv4i"
                        dataset="production"
                        />
                </div>
                <button class="btn btn-primary" > 
                <a href="/home">
                    Enter</a>
                </button>
            </section>
        </main>
    )
}