import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function Productions() {
    const [productionsData, setProductions] = useState(null);

    useEffect(() => {
        sanityClient
        .fetch(
            `*[_type == "productions"]{
            title, 
            producer,
            slug, 
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }
        }`)
        .then((data) => setProductions(data))
        .catch(console.error)
    }, []); 

    if (!productionsData) return <div className="loading">Loading...</div>;
    
    return (
        <main>
            <div className="back-blur"></div>
            <section>
                <h1>Productions</h1>
                <div className="container">
                {productionsData && productionsData.map((productions, index) => (
                    <article>
                        <div className="box">
                            <Link to={"/productions/" + productions.slug.current} key={productions.slug.current}
                            ><img src={urlFor(productions.mainImage).url()} alt="" />
                                <span key={index}>
                                    <h3>{productions.title}</h3>
                                </span>
                            </Link>
                            <div className="box-body">{productions.producer}</div>
                        </div>
                    </article>
                ))}
                </div>
            </section>
        </main>
    )
}