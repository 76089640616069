// style utilities that return CSS strings

export const boxflex = () => {
  return `
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    `;
};

export const centerContents = () => {
  return `
        display: flex;
        justify-content: center;
        align-items: center;
    `;
};
