// import SanityBlockContent from "@sanity/block-content-to-react";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../client.js";
import BlockContent from "@sanity/block-content-to-react";

export default function List() {
    const [listData, setList] = useState(null);

    useEffect(() => {
        sanityClient
        .fetch(
            `*[_type == "whiteboard"]{
                title, 
                body,
                categories
            }`)
        .then((data) => setList(data))
        .catch(console.error)
    }, []); 

    // if (!listData) return <div className="loading">Loading...</div>;

    return (

            <div className="list">
                <div className="container">
                {listData && listData.map((list, index) => (
                    <article>
                        <div className="box">
                            <Link to={"/Whiteboard/" + list.slug.current} key={list.slug.current}
                            >
                                <span key={index}>
                                    <h3>{list.title}</h3>
                                </span>
                            </Link>
                            <div className="box-body">
                            <BlockContent
                        blocks={list.body}
                        projectId="i4lsnv4i"
                        dataset="production"
                        />
                            </div>
                            <div className="box-body">
                            {list.categories}
                            </div>
                        </div>
                        {/* <div className="box">
                        <Link to={"/merch/" + merch.slug.current} key={merch.slug.current}
                        >
                            <span key={index}>
                                <h3>{merch.title}</h3>
                            </span>
                        </Link>
                            <div className="box-body">
                                <BlockContent
                                blocks={merch.description}
                                projectId="i4lsnv4i"
                                dataset="production"
                                />
                            </div>
                        </div> */}
                    </article>
                ))}
                </div>
            </div>
    )
}