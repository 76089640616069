import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ToggleList from "./ToggleList";
import List from "./List";
  
export default function Help() {
    return (
        <div className="help-me">
            <div className="help-item"><NavLink to="/about">
                ?
            </NavLink>
            <ToggleList><List /></ToggleList>
            </div>
            <div className="close-item">
            <NavLink to="/Home">
                ×
            </NavLink>
            </div>
        </div>
    )
}
