// breakpoints based on _variables.scss from `ui-website`
// 🔗 https://github.com/Groupe-Atallah/ui-website/blob/master/src/client/styles/_variables.scss

// to be used as minimum values eg:
// @media (min-width: ${bp.md}) {
//     // css for everything above 768px / ~desktop
// }

// to add this ^ as a snippet to vscode ⤵
//
// shift + command + p
// > "configure user snippets"
// > "css" (vscode will know)
// copy and paste this snippet in ⤵
//     "Insert breakpoint (JSX)": {
//         "prefix": "@bp",
//         "body": ["@media (min-width: ${bp.$1}) {", "    $0", "}"],
//         "description": "Insert breakpoint (JSX)"
//     }
//
// then, to use type "@bp" inside some css, and select the snippet, from here you can type in any of the sizes, and you should be good to go :~)

export const bpR = {
  // raw screen widths
  xs: 375,
  sm: 576,
  md: 768,
  // desktop starts below ⤵
  lg: 992,
  xl: 1200,
  // extra large window sizes below ⤵
  xxl: 1920,
  xxxl: 2560,
};

export const bp = {
  // screen widths in pixels
  xs: `${bpR.xs}px`,
  sm: `${bpR.sm}px`,
  md: `${bpR.md}px`,
  lg: `${bpR.lg}px`,
  xl: `${bpR.xl}px`,
  xxl: `${bpR.xxl}px`,
  xxxl: `${bpR.xxxl}px`,
};
