import React from "react";
import { NavLink } from "react-router-dom";


export default function Nav() {
    return (
        <header>
            {/* <div className="home"><NavLink to="/" exact>artPost21</NavLink></div> */}
            <nav>
                <div className="nav-item"><NavLink to="/books">
                    Books
                </NavLink></div>
                <div className="nav-item"><NavLink to="/merch">
                    Merch
                </NavLink></div>
                <div className="nav-item"><NavLink to="/podcast">
                    Podcast
                </NavLink></div>
                <div className="nav-item"><NavLink to="/videos">
                    Video Work
                </NavLink></div>
                <div className="nav-item"><NavLink to="/whiteboard">
                    Whiteboard
                </NavLink></div>
                <div className="nav-item"><NavLink to="/leftDoor">
                    Left Door
                </NavLink></div>
                <div className="nav-item"><NavLink to="/rightDoor">
                    Right Door
                </NavLink></div>
            </nav>
        </header>
    )
}