// import SanityBlockContent from "@sanity/block-content-to-react";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../client.js";
import BlockContent from "@sanity/block-content-to-react";

export default function MerchList() {
    const [listData, setList] = useState(null);

    useEffect(() => {
        sanityClient
        .fetch(
            `*[_type == "merch"]{
            title,
            slug,
            details,
            categories
        }`)
        .then((data) => setList(data[0]))
        .catch(console.error)
    }, []); 

    // if (!list) return <div className="loading">Loading...</div>

    return (
        <div className="container">
        {listData && listData.map((list, index) => (
            <article>
                <div className="box">
                    <Link to={"/videos/" + list.slug.current} key={list.slug.current}
                    >
                        <span key={index}>
                            <h3>{list.title}</h3>
                        </span>
                    </Link>
                    <div className="box-body">
                    <BlockContent
                blocks={list.details}
                projectId="i4lsnv4i"
                dataset="production"
                />
                    </div>
                    {/* <div className="box-body">
                    {merchList.categories}
                    </div> */}
                </div>
                {/* <div className="box">
                <Link to={"/merch/" + merch.slug.current} key={merch.slug.current}
                >
                    <span key={index}>
                        <h3>{merch.title}</h3>
                    </span>
                </Link>
                    <div className="box-body">
                        <BlockContent
                        blocks={merch.description}
                        projectId="i4lsnv4i"
                        dataset="production"
                        />
                    </div>
                </div> */}
            </article>
        ))}
        </div>
    )
}