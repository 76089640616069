import React from "react";
import "../nav.css";
import LandingPage from "./LandingPage";

export default function Landing() {
    return (
        <div className="landing-page">
            <iframe className="home-background" src="https://artpost21.vercel.app/landing/pano.html" title="background"></iframe>
            <LandingPage />
        </div>
    )
}