export const color = {
  light: {
    main: '#000',
    mainRGB: '0, 0, 0',
    background: '#fff',
    backgroundRGB: '255, 255, 255',
    highlight: '#f2f2f2',
  },
  dark: {
    main: '#fff',
    mainRGB: '255, 255, 255',
    background: '#000',
    backgroundRGB: '0, 0, 0',
    highlight: '#333',
  },
  utility: {
    red: '#FF6240',
    orange: '#FF9040',
    yellow: '#FADE7A',
    green: '#2AD546',
    blue: '#008EFF',
    indigo: '#615FD3',
  },
  greyscale: [
    // flipped [10 → 0] when dark
    '#000000', // 0
    '#1A1A1A', // 1
    '#333333', // 2
    '#4C4C4C', // 3
    '#666666', // 4
    '#808080', // 5
    '#999999', // 6
    '#B3B3B3', // 7
    '#CCCCCC', // 8
    '#E5E5E5', // 9
    '#F5F5F5', // 10
    '#FFFFFF', // 11
  ],
};

const unit = '5px';

const theme = {
  type: {
    baseFontSize: 14, // base font size *in `px`* (used for baseline calculations) - will become equal to `1rem`
  },
  transition: 'all 0.2s ease-in-out',
  unit,
  margin: `calc(${unit} * 10)`,
  borderRadius: `calc(${unit} * 2)`,
};

export default theme;
