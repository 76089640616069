import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function Booksandeditions() {
    const [booksData, setBooks] = useState(null);

    useEffect(() => {
        sanityClient
        .fetch(
            `*[_type == "books"]{
            title, 
            author,
            slug, 
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }
        }`)
        .then((data) => setBooks(data))
        .catch(console.error)
    }, []); 

    if (!booksData) return <div className="loading">Loading...</div>;
    
    return (
        <main>
            <div className="back-blur"></div>
            <section>
                <h1>Books and Editions</h1>
                <div className="container">
                {booksData && booksData.map((books, index) => (
                    <article className="books-container">
                        <div className="box">
                            <Link to={"/booksandeditions/" + books.slug.current} key={books.slug.current}
                            ><img src={urlFor(books.mainImage).url()} alt="" />
                                <span key={index}>
                                    <h3>{books.title}</h3>
                                </span>
                            </Link>
                            <div className="box-body">{books.author}</div>
                        </div>
                    </article>
                ))}
                </div>
            </section>
        </main>
    )
}