// import SanityBlockContent from "@sanity/block-content-to-react";
import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function Podcast() {
    const [podcastData, setPodcast] = useState(null);

    useEffect(() => {
        sanityClient
        .fetch(
            `*[_type == "podcast"]{
            name,
            description,
            details
        }`
    )
        .then((data) => setPodcast(data))
        .catch(console.error)
    }, []); 

    if (!podcastData) return <div className="loading">Loading...</div>

    return (
        <main>
            <div className="back-blur"></div>
            <section>
                <h1>The aP21 Podcast</h1>
                <div className="container">
                {podcastData && podcastData.map((podcast, index) => (
                    <article>
                    <h3 className="box-main">{podcast.name}</h3>

                    <div className="pod">
                        <div className="box-main">
                            <BlockContent
                            blocks={podcast.description}
                            projectId="i4lsnv4i"
                            dataset="production"
                            />
                        </div>

                        <div className="box-main details">
                            <BlockContent
                            blocks={podcast.details}
                            projectId="i4lsnv4i"
                            dataset="production"
                            />
                        </div>
                    </div>
                    </article>
                ))}
                </div>
            </section>
        </main>
    )
}