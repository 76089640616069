// import SanityBlockContent from "@sanity/block-content-to-react";
import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import { useForm, ValidationError } from '@formspree/react';
import ContactForm from "./ContactForm";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function About() {
    const [about, setAbout] = useState(null);

    useEffect(() => {
        sanityClient
        .fetch(
            `*[_type == "about"]{
            title,
            image{
                asset->{
                    _id,
                    url
                },
                alt
            },
            bio
        }`)
        .then((data) => setAbout(data[0]))
        .catch(console.error)
    }, []); 

    if (!about) return <div className="loading">Loading...</div>

    return (
        <main>
            <div className="back-blur"></div>
            <section>
            <h1>About</h1>
                <div className="container about-page">
                    <h3 className="box-main">{about.title}</h3>
                    
                    <div className="box-main">
                        <img src={urlFor(about.image).url()} alt=""/>
                    </div>
                    <br/>

                    <div className="box-main"><span className="subscribe">Subscribe to our Newsletter</span>
                        <br/> <br/>
                        <ContactForm />
                    </div>
                    <br/><br/>
                    
                    <div className="box-main about-main">
                        <BlockContent
                        blocks={about.bio}
                        projectId="i4lsnv4i"
                        dataset="production"
                        />
                    </div>
                </div>
            </section>
        </main>
    )
}