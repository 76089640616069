import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from "./components/Home";
import SingleBooks from "./components/SingleBooks";
import Booksandeditions from "./components/booksandeditions";
import SingleProductions from "./components/SingleProductions";
import Productions from "./components/productions";
import SingleMerch from "./components/SingleMerch";
import Collections from "./components/collections";
import Theap21podcast from "./components/theap21podcast";
import Video from "./components/Video";
import SingleThescreen from "./components/SingleThescreen";
import Thescreen from "./components/thescreen";
import SingleWhiteboard from "./components/SingleWhiteboard";
import Happenings from "./components/happenings";
import Theageofanxiety from "./components/theageofanxiety";
import Thecenterofculturalconfusion from "./components/thecenterofculturalconfusion";
import About from "./components/about";
import Nav from "./components/Nav";
import Help from "./components/Help";
import ToggleVisibility from "./components/ToggleVisibility";
import ToggleList from "./components/ToggleList";
import List from "./components/List";
import Landing from "./components/Landing";
import MerchList from "./components/Lists/MerchList";
import LeftDoorList from "./components/Lists/LeftDoorList";
import PodcastList from "./components/Lists/PodcastList";
import RightDoorList from "./components/Lists/RightDoorList";
import VideosList from "./components/Lists/VideosList";
import WhiteboardList from "./components/Lists/WhiteboardList";
import ContactForm from "./components/ContactForm";

function App() {
  useEffect(() => {
    const handleEsc = (event) => {
       if (event.keyCode === 27) {
        console.log('Close')
        window.location.href = `/home`;
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    }, []);
  return (
    <Router>
      <iframe className="home-background" src="https://artpost21.vercel.app/pano.html" title="background"></iframe>
        <div className="header">
        <ToggleVisibility><Nav /></ToggleVisibility>
        <Help />
        </div>
        <Routes>
          <Route exact path="/" element={<Landing/>}/>
          <Route path="/booksandeditions/:slug" element={<SingleBooks/>}/>
          <Route path="/booksandeditions" element={<Booksandeditions/>}/>
          <Route path="/productions/:slug" element={<SingleProductions/>}/>
          <Route path="/productions" element={<Productions/>}/>
          <Route path="/collections/:slug" element={<SingleMerch/>}/>
          <Route path="/collections" element={<Collections/>}/>
          <Route path="/theap21podcast" element={<Theap21podcast/>}/>
          <Route path="/thescreen/:slug" element={<SingleThescreen/>}/>
          <Route path="/thescreen" element={<Thescreen/>}/>
          <Route path="/video" element={<Video/>}/>
          <Route path="/happenings/:slug" element={<SingleWhiteboard/>}/>
          <Route path="/happenings" element={<Happenings/>}/>
          <Route path="/theageofanxiety" element={<Theageofanxiety/>}/>
          <Route path="/thecenterofculturalconfusion" element={<Thecenterofculturalconfusion/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/Landing" element={<Landing/>}/>
          <Route path="/List" element={<List/>}/>
          <Route path="/MerchList" element={<MerchList/>}/>
          <Route path="/LeftDoorList" element={<LeftDoorList/>}/>
          <Route path="/PodcastList" element={<PodcastList/>}/>
          <Route path="/RightDoorList" element={<RightDoorList/>}/>
          <Route path="/VideosList" element={<VideosList/>}/>
          <Route path="/WhiteboardList" element={<WhiteboardList/>}/>
        </Routes>
    </Router>
  )
}

export default App;