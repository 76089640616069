import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function Collections() {
    const [merchData, setMerch] = useState(null);

    useEffect(() => {
        sanityClient
        .fetch(
            `*[_type == "merch"]{
            title, 
            slug, 
            intro,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }
        }`)
        .then((data) => setMerch(data))
        .catch(console.error)
    }, []); 

    if (!merchData) return <div className="loading">Loading...</div>;

    return (
        <main>
            <div className="back-blur"></div>
            <section>
                <h1>Collections</h1>
                <div className="container"> {merchData && merchData.map((merch, index) => (
                    <article className="merch-container">
                        <div className="box merch-box">
                        <BlockContent
                                blocks={merch.intro}
                                projectId="i4lsnv4i"
                                dataset="production"
                                />
                            <Link to={"/collections/" + merch.slug.current} key={merch.slug.current}
                            >
                                <img src={urlFor(merch.mainImage).url()} alt="" />
                                <span key={index}>
                                    <h3>{merch.title}</h3>
                                </span>
                            </Link>
                        </div>
                    </article>
                ))}
                </div>
            </section>
        </main>
    )
}