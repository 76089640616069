import React, { useEffect, useState } from "react";
import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";
import { useParams, useNavigate } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const serializers = {
  types: {
    youtube: ({node}) => {
      const { url } = node
      const id = getYouTubeId(url)
      return (<YouTube videoId={id} />)
    }
  }
}

export default function SingleThescreen() {
    const navigate = useNavigate();
    const [singleThescreenData, setSingleThescreenData] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        sanityClient
          .fetch(
            `*[slug.current == "${slug}"]{
                name,
                description
              }`
          )
          .then((data) => setSingleThescreenData(data[0]))
          .catch(console.error);
      }, [slug]);

  if (!singleThescreenData) return <div className="loading">Loading...</div>;

  return (
    <div className="post">
      <div className="back-blur"></div>
      <button className="back" onClick={() => navigate(-1)}>←</button> 
      <div className="video-container">
          <h3>{singleThescreenData.name}</h3>

          <div className="box-main-vid">
              <BlockContent
              blocks={singleThescreenData.description}
              projectId="i4lsnv4i"
              dataset="production"
              serializers={serializers}
              />
          </div>
      </div>
    </div>
  );
}