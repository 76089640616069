import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function SingleProductions() {
    const navigate = useNavigate();
    const [singleProductionsData, setSingleProductionsData] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        sanityClient
          .fetch(
            `*[slug.current == "${slug}"]{
                title,
                producer,
                slug,
                mainImage{
                  asset->{
                    _id,
                    url
                  }
                },
                description,
                url,
                publishedAt,
                language,
                details,
                status
              }`
          )
          .then((data) => setSingleProductionsData(data[0]))
          .catch(console.error);
      }, [slug]);

  if (!singleProductionsData) return <div className="loading">Loading...</div>;

  return (
    <div className="post">
      <div className="back-blur"></div>
      <button className="back" onClick={() => navigate(-1)}>←</button> 
      <div className="inner-post">
        <div className="post-image"><img src={urlFor(singleProductionsData.mainImage).url()} alt="" /></div>

        <br/>

        <div className="post-title">{singleProductionsData.title}</div>

        <div className="post-body">
        {singleProductionsData.producer}
        </div>

        <div className="post-body">
          <BlockContent
            blocks={singleProductionsData.description}
            projectId="i4lsnv4i"
            dataset="production"
          />
        </div>

        <div className="post-span">
          <span>Published at {singleProductionsData.publishedAt}</span>, <span>in {singleProductionsData.language}</span>
        </div>

        <div className="post-span">
          <BlockContent
            blocks={singleProductionsData.details}
            projectId="i4lsnv4i"
            dataset="production"
          />
        </div>

        {/* <div className="post-span">STATUS<br /> {singleProductionsData.status}</div>

        <br/> */}

        {/* <div className="checkout"><a target="_blank"href={singleProductionsData.url}>Add to basket →</a></div> */}
    </div>
    </div>
  );
}