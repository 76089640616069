import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { bp } from '../helpers/breakpoints'
import { type } from '../helpers/type'
import { boxflex } from '../helpers/style'

const Video = ({
    width,
    height,
    autoPlay,
    loop,
    muted,
    controls,
    controlsList,
    src,
    // subtitle attributes ⤵
    label,
    kind,
    srcLang,
    vttSrc,

    setLoadedFunction, // setState function passed to this component, to set loaded to true once the video is loaded
}) => {
    const videoRef = useRef(null)

    const [showCaptions, setShowCaptions] = useState(false)
    const [activeCaption, setActiveCaption] = useState('')

    const captionVideo = () => {
        const video = videoRef.current
        const track = video.textTracks[0]
        track.mode = 'hidden' // hide browser captions

        track.addEventListener('cuechange', () => {
            const text =
                track.activeCues.length > 0 ? track.activeCues[0].text : null // array of current cues
            if (text !== null) {
                setShowCaptions(true)
                setActiveCaption(text)
            } else {
                setShowCaptions(false)
            }
        })
    }

    useEffect(() => {
        // we can use `setLoadedFunction` here, which accepts a setState function passed to this component,
        // to tell this component's parent that loading is complete  once the video is loaded
        if (setLoadedFunction) {
            const video = videoRef.current
            video.onloadeddata = () => {
                setLoadedFunction(true)
            }
        }
    }, [setLoadedFunction, videoRef])

    return (
        <VideoContainer width={width} height={height}>
            <video
                ref={videoRef}
                width={width}
                height={height}
                autoPlay={autoPlay}
                loop={loop}
                muted={muted}
                playsInline={true}
                controls={controls}
                controlsList={controlsList}
                onCanPlay={() => {
                    captionVideo()
                }}
            >
                <source src={src} type="video/mp4" />

                <track
                    label={label}
                    kind={kind}
                    srcLang={srcLang}
                    src={vttSrc}
                    default
                />
            </video>
            {showCaptions && <Captions>{activeCaption}</Captions>}
        </VideoContainer>
    )
}

export default Video

const VideoContainer = styled.div`
    position: relative;
    ${boxflex()};

    width: ${({ width }) => (width ? width : 'auto')};
    height: ${({ height }) => (height ? height : 'auto')};

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    & > div {
        // the container for both the video and the captions
        max-width: 100%;
        height: auto;

        @media (min-width: ${bp.md}) {
            width: auto;
        }
    }
`
const Captions = styled.div`
    width: 60%;
    margin: 30px 20%;
    position: absolute;
    bottom: 0;

    ${type(0.75)}
    text-align: center;
    color: rgb(10, 92, 164);
    text-shadow: rgb(255 255 255) 0px 0px 0.5em;

    @media (min-width: ${bp.md}) {
        ${type(1)}
        margin: ${type * 2 * 1}px 20%;
    }

    @media (min-width: ${bp.lg}) {
        ${type(1.25)}
        margin: ${type * 2 * 1.25}px 20%;
    }

    @media (min-width: ${bp.xl}) {
        ${type(1.5)}
        margin: ${type * 2 * 1.5}px 20%;
    }
`
