import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

export default function ContactForm() {
  const [state, handleSubmit] = useForm("xayvgazq");
  if (state.succeeded) {
      return <p>Thanks for joining!</p>;
  }
  return (
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">
          Email Address
        </label>
        <input
          id="email"
          type="email" 
          name="email"
        />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      <br /> <br />
      Message
      <textarea
        id="message"
        name="message"
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      /><br/>
      <button className='submit' type="submit" disabled={state.submitting}>
        Submit
      </button>
    </form>
  );
}