import theme from '../components/theme';

const { baseFontSize } = theme.type;
export const baseline = (multiplier, { overrideBaseFontSize }) => {
  const baselineSize = overrideBaseFontSize
    ? overrideBaseFontSize // override base font size if passed in
    : baseFontSize; // otherwise use the default base font size set in `theme`
  return `${baselineSize * multiplier}px`;
};

export const type = (multiplier, overrideBaseFontSize) => {
  const baseLineHeight = 1.2;
  let base = baseFontSize; // use the default base font size set in `theme`

  if (overrideBaseFontSize) {
    // override base font size if overrideBaseFontSize is passed in

    if (typeof overrideBaseFontSize === 'string') {
      if (overrideBaseFontSize.includes('px')) {
        // if px, slice out "px"
        base = overrideBaseFontSize.slice(0, -2);
      } else {
        // if "rem", get rem value from baseFontSize
        base = baseFontSize * overrideBaseFontSize.slice(0, -3);
      }
    } else {
      base = overrideBaseFontSize;
    }
  }

  const typeValues = `
        font-size: ${base * multiplier}px;
        line-height: ${baseLineHeight}em;
        letter-spacing: -${base * multiplier * 0.008333}px;
    `;
  return typeValues;
};
