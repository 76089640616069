import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function SingleBooks() {
    const navigate = useNavigate();
    const [singleBooksData, setSingleBooksData] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        sanityClient
          .fetch(
            `*[slug.current == "${slug}"]{
                title,
                author,
                slug,
                mainImage{
                  asset->{
                    _id,
                    url
                  }
                },
                description,
                url,
                publishedAt,
                language,
                details,
                isbn,
                status
              }`
          )
          .then((data) => setSingleBooksData(data[0]))
          .catch(console.error);
      }, [slug]);

  if (!singleBooksData) return <div className="loading">Loading...</div>;

  return (
    <div className="post">
      <div className="back-blur"></div>
      <button className="back" onClick={() => navigate(-1)}>←</button> 
      <div className="inner-post">
        <div className="post-image"><img src={urlFor(singleBooksData.mainImage).url()} alt="" /></div>

        <br/>

        <div className="post-title">{singleBooksData.title}</div>

        <div className="post-body">
        {singleBooksData.author}
        </div>

        <div className="post-body">
          <BlockContent
            blocks={singleBooksData.description}
            projectId="i4lsnv4i"
            dataset="production"
          />
        </div>

        <div className="post-span">
          <span>Published at {singleBooksData.publishedAt}</span>, <span>in {singleBooksData.language}</span>
        </div>

        <div className="post-span">
          <BlockContent
            blocks={singleBooksData.details}
            projectId="i4lsnv4i"
            dataset="production"
          />
        </div>

        <div className="post-span">ISBN: {singleBooksData.isbn}</div>

        <div className="post-span">STATUS<br /> {singleBooksData.status}</div>

        {/* <br/>

        <div className="checkout"><a target="_blank"href={singleBooksData.url}>Add to basket →</a></div> */}
    </div>
    </div>
  );
}