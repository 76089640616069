// import SanityBlockContent from "@sanity/block-content-to-react";
// import React from 'react'

// import Video from './Video'

// import fetchData from '../helpers/sanity/server'
// import { allQuery } from '../helpers/sanity/queries'

// function Thescreen({ title, data }) {
//     console.log(data)

//     const music = data.music.videos
//     const film = data.film

//     console.log(film)

//     return (
//         <main>
//             <section>
//              <h1>Videos</h1>             
//              <div className="container">
//              {film.map((item, index) => (
//                                     <div
//                                         key={`${index}-${item.title}`}
//                                         onClick={() => handleVideoSelect(item)}
//                                     >
//                                         {item.title}
//                                     </div>
//                                 ))}
//             </div>
//         </section>
//         </main>
//     )
// }

// export async function getStaticProps(context) {
//     const data = await fetchData(allQuery)

//     return {
//         props: {
//             title: 'artpost21',
//             data: data,
//         }, // will be passed to the page component as props
//     }
// }

// export default Thescreen




// in my own writing:

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import sanityClient from "../client.js";
// import BlockContent from "@sanity/block-content-to-react";
// import imageUrlBuilder from "@sanity/image-url";


// export default function Thescreen() {

//     const [thescreenData, setThescreen] = useState(null);

//     useEffect(() => {
//         sanityClient
//         .fetch(
//             `*[_type == "films"]{
//                 "videos": videos[] {
//                   "cover": video.cover.asset->,
//                   "file": video.video.asset->,
//                   "title": video.title
//                 },
//               }`
//     )
//         .then((data) => setThescreen(data))
//         .catch(console.error)
//     }, []); 

//     if (!thescreenData) return <div className="loading">Loading...</div>

//     return (
//         <main>
//             <div className="back-blur"></div>
//             <section>
//             <h1>Videos</h1>
//             <div className="container">{thescreenData && thescreenData.map((screen, index) => (
//                     <div key={index}>
//                         {screen.title}
//                         {/* <Video src={screen.file} controls autoPlay /> */}
//                         </div>
//                 ))}
//             </div>
//             </section>
//         </main>
//     )
// }
 













// on the site rn:

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const serializers = {
    types: {
      youtube: ({node}) => {
        const { url } = node
        const id = getYouTubeId(url)
        return (<YouTube videoId={id} />)
      }
    }
}

export default function Thescreen() {
    const [videosData, setVideos] = useState(null);

    useEffect(() => {
        sanityClient
        .fetch(
            `*[_type == "thescreen"]{
              name,
              slug,
              description
        }`)
        .then((data) => setVideos(data))
        .catch(console.error)
    }, []); 

    if (!videosData) return <div className="loading">Loading...</div>

    return (
        <main>
            <div className="back-blur"></div>
            <section>
                <h1>The Screen</h1>
                <div className="container video-page">
                {videosData && videosData.map((videos, index) => (
                    <div className="video-container">
                        <Link className="video-link" to={"/thescreen/" + videos.slug.current} key={videos.slug.current}
                            ><span key={index}><h3>{videos.name}</h3></span>
                        </Link>

                        <div className="box-main">
                            <BlockContent
                            blocks={videos.description}
                            projectId="i4lsnv4i"
                            dataset="production"
                            serializers={serializers}
                            />
                        </div>
                    </div>
                ))}
                </div>
            </section>
        </main>
    )
}