import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";

export default function List() {
    const [listData, setList] = useState(null);

    useEffect(() => {
        sanityClient
        .fetch(
            `*[_type == "list"]{
            productname, 
            url,
            author,
            categories
        }`)
        .then((data) => setList(data))
        .catch(console.error)
    }, []); 

    function hide(obj) {
        var el = document.getElementById(obj);
        el.style.display = 'none';
    }

    // if (!listData) return <div className="loading">Loading...</div>;
    
    return (
        <main className="list-page" id='hideme'>
            <section>
                <div className="list">
                <div className="container">
                {listData && listData.map((list, index) => (
                    <article>
                        <div className="box">
                            <Link onClick="hide('hideme')" to={list.url} key={list.url}
                            ><span key={index}>
                                    <h3>{list.productname}</h3>
                                </span>
                            </Link>
                            <div className="box-body">{list.author}</div>
                            <div className="box-body">
                            {list.categories}
                            </div>
                        </div>
                    </article>
                ))}
                </div>
                </div>
            </section>
        </main>
    )
}



//     return (
//         <main className="list-page">
//             <section>
//             <div className="list">
//                 <div className="container">
//                 {listData && listData.map((list, index) => (
//                     <article>
//                         <div className="box">
//                             <Link to={"/list/" + list.slug.current} key={list.slug.current}
//                             >
//                                 <span key={index}>
//                                     <h3>{list.title}</h3>
//                                 </span>
//                             </Link>
//                             <div className="box-body">
//                             {list.author}
//                             </div>
//                             <div className="box-body">
//                             {list.categories}
//                             </div>
                            
//                         </div>
                        
//                         {/* <div className="box">
//                         <Link to={"/merch/" + merch.slug.current} key={merch.slug.current}
//                         >
//                             <span key={index}>
//                                 <h3>{merch.title}</h3>
//                             </span>
//                         </Link>
//                             <div className="box-body">
//                                 <BlockContent
//                                 blocks={merch.description}
//                                 projectId="i4lsnv4i"
//                                 dataset="production"
//                                 />
//                             </div>
//                         </div> */}
//                     </article>
                    
//                 ))}
//                 </div>
//                 {/* <MerchList/>
//                 <ListList/>
//                 <PodcastList/>
//                 <RightDoorList/>
//                 <VideosList/>
//                 <WhiteboardList/> */}
//             </div>
//             </section>
            
//         </main>
//     )
// }