// import SanityBlockContent from "@sanity/block-content-to-react";
import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function RightDoor() {
    const [rightDoor, setRightDoor] = useState(null);

    useEffect(() => {
        sanityClient
        .fetch(
            `*[_type == "rightDoor"]{
            title,
            body
        }`)
        .then((data) => setRightDoor(data[0]))
        .catch(console.error)
    }, []); 

    if (!rightDoor) return <div className="loading">Loading...</div>

    return (
        <main>
            <div className="back-blur"></div>
            <section>
                <h1>The Center of Cultural Confusion</h1>
                <div className="container">
                    <h3 className="box-main">{rightDoor.title}</h3>
                    
                    <div className="box-main">
                        <BlockContent
                        blocks={rightDoor.body}
                        projectId="i4lsnv4i"
                        dataset="production"
                        />
                    </div>
                </div>
            </section>
        </main>
    )
}