import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function Happenings() {
    const [whiteboardData, setWhiteboard] = useState(null);

    useEffect(() => {
        sanityClient
        .fetch(
            `*[_type == "whiteboard"]{
            title, 
            slug, 
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            },
            publishedAt,
            body,
            category,
            link 
        }`)
        .then((data) => setWhiteboard(data))
        .catch(console.error)
    }, []); 

    if (!whiteboardData) return <div className="loading">Loading...</div>;

    return (
        <main>
            <div className="back-blur"></div>
            <section>
                <h1>Happenings</h1>
                <div className="container"> {whiteboardData && whiteboardData.map((whiteboard, index) => (
                    <article>
                        <div className="box">
                            <Link to={"/happenings/" + whiteboard.slug.current} key={whiteboard.slug.current}
                            >
                                <span key={index}>
                                    <h3>{whiteboard.title}</h3>
                                </span>
                                <img src={urlFor(whiteboard.mainImage).url()} alt="" />
                            </Link>
                            <div className="box-body">
                                <BlockContent
                                    blocks={whiteboard.description}
                                    projectId="i4lsnv4i"
                                    dataset="production"
                                />
                            </div>
                            <div className="box-body">
                                <BlockContent
                                    blocks={whiteboard.body}
                                    projectId="i4lsnv4i"
                                    dataset="production"
                                />
                            </div>
                        </div>
                    </article>
                ))}
                </div>
            </section>
        </main>
    )
}