
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function SingleWhiteboard() {
    const navigate = useNavigate();
    const [singleWhiteboardData, setSingleWhiteboardData] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        sanityClient
          .fetch(
            `*[slug.current == "${slug}"]{
                title,
                slug,
                mainImage{
                  asset->{
                      _id,
                      url
                  },
                  alt
                },
                publishedAt,
                body,
                category,
                link   
              }`
          )
          .then((data) => setSingleWhiteboardData(data[0]))
          .catch(console.error);
      }, [slug]);

  if (!singleWhiteboardData) return <div className="loading">Loading...</div>;

  return (
    <div className="post">
    <div className="inner-post">
      <div className="back-blur"></div>
      <button className="back" onClick={() => navigate(-1)}>←</button>
      <div className="post-title">{singleWhiteboardData.title}</div>

        <div className="post-image"><img src={urlFor(singleWhiteboardData.mainImage).url()} alt="" /></div>

        <div className="post-body">
          <BlockContent
            blocks={singleWhiteboardData.description}
            projectId="i4lsnv4i"
            dataset="production"
          />
        </div>

        <div className="post-body">
          <BlockContent
            blocks={singleWhiteboardData.body}
            projectId="i4lsnv4i"
            dataset="production"
          />
        </div>

    </div>
    </div>
  );
}